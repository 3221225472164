body{
    display: grid;
    place-items: center;
    justify-content: space-around;
    min-height: 100vh;
    font-family: "Source Sans Pro", sans-serif;
    background-color:rgb(255, 255, 255);
}

.start{
    font-family: Mahone;
    font-size: 4em;
    color: black;
    text-decoration: none;
    -webkit-animation: slide-in-blurred-bottom 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
    animation: slide-in-blurred-bottom 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
}


 @-webkit-keyframes slide-in-blurred-bottom {
    0% {
      -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
              transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
              transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-blurred-bottom {
    0% {
      -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
              transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
              transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  


 @-webkit-keyframes slide-in-start {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-start {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  

h1{
    color: rgb(245, 122, 3);
    font-size: 15vw;
    font-family: Mahone;
}

.tracking-in-expand {
	-webkit-animation: tracking-in-expand 1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-expand 1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

 @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }