body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow : hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Mahone;
  src: url(MahoneSansBold.ttf);
}


/***


  display: grid;
  place-items: center;
  justify-content: space-around;
  min-height: 100vh;
  font-family: "Source Sans Pro", sans-serif;
  background: #ffffff;
  background-image: linear-gradient(45deg, rgba(255, 158, 128, 0.7), rgba(98, 0, 234, 0.8));



***/