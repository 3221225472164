


.animation-entrance {
    animation: slide-in-bottom 1s forwards;
}

.moove[anim='1'] {
    animation: slide-out-top 1s forwards, slide-in-bottom 0.5s 0.4s forwards;
    -webkit-animation: slide-out-top 1s forwards, slide-in-bottom 0.5s 0.4s forwards;
}

.moove[anim='2'] {
    animation: slide-out-bottom 1s forwards, slide-in-top 0.5s 0.5s forwards;
    -webkit-animation: slide-out-bottom 1s forwards, slide-in-top 0.5s 0.5s forwards;
}

@-webkit-keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 1;
    }
}

@keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 1;
    }
}

@-webkit-keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes slide-out-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 1;
    }
}

@keyframes slide-out-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 1;
    }
}

@-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}





.card .front,
.card .back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: transform 0.8s ease-in-out;
}

/*     background-image: url(https://images.unsplash.com/photo-1604076850742-4c7221f3101b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80);
*/

.card .front .img,
.card .back .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
    filter: grayscale(70%) brightness(40%);
}

.card .front {
    background-color: rgb(245, 122, 3);
}

.card .back {
    background-color: rgb(225, 85, 9);
    transform: rotateY(180deg);
}

.card:hover .front {
    transform: rotateY(-180deg);
}

.card:hover .back {
    transform: rotateY(0deg);
}

.card .contents {
    color: rgb(0, 0, 0);
    text-align: center;
    transform: translateZ(100px);
}

.card .contents .title::after,
.card .contents .subtitle::after {
    content: "";
    display: block;
    width: 30px;
    height: 2px;
    margin: 1rem auto;
    background: #000000;
}

@keyframes title-anim {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.button {
    background-color: #99c0ff;
    border: none;
    color: white;
    padding: 20px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 56px;
    margin: 4px 2px;
    cursor: pointer;
}

@media screen and (min-width : 688px) {

    .card {
        position: relative;
        width: 60vw;
        height: 60vh;
        max-width: 500px;
        max-height: 600px;
        margin: 1rem;
        transform-style: preserve-3d;
        perspective: 800px;
        z-index: 2;
        transform: translateY(-40%)
    }

    .card .contents .title {
        font-family: Mahone;
        font-size: 5em;
        animation: title-anim 2s forwards;
    }

    .card .contents .subtitle {
        font-family: Mahone;
        font-size: 2em;
        line-height: 1em;
        width: auto;
    }
    
    .card .contents .text {
        font-family: Mahone;
        font-size: 1.5em;
        line-height: 1em;
    }


    .next-direction{
        font-size: 50px;
        transform: translateY(500%) scale(5);
        z-index: 0;
        display: inline-block;
        cursor: crosshair;
        font-family: Mahone;
        line-height: 1em;
    }
    
    .back-direction{
        font-size: 50px;
        transform:  scale(5);
        z-index: 0;
        display: inline-block;
        cursor: crosshair;
        font-family: Mahone;
        line-height: 1em;
    }

}

@media screen and (max-width: 687px) {

    .card {
        position: relative;
        width: 85vw;
        height: 60vh;
        max-width: 500px;
        max-height: 600px;
        margin: 1rem;
        transform-style: preserve-3d;
        perspective: 800px;
        z-index: 2;
        transform: translateY(-40%)
    }

    .card .contents .title {
        font-family: Mahone;
        font-size: 2em;
        animation: title-anim 2s forwards;
    }

    .card .contents .subtitle {
        font-family: Mahone;
        font-size: 1.5em;
        line-height: 1em;
        width: auto;
    }
    
    .card .contents .text {
        font-family: Mahone;
        font-size: 1em;
        line-height: 1em;
    }

    .next-direction{
        font-size: 10vw;
        transform: translateY(400%) scale(3.5);
        z-index: 0;
        display: inline-block;
        cursor: crosshair;
        font-family: Mahone;
        line-height: 1em;
    }
    
    .back-direction{
        font-size: 10vw;
        transform:  scale(3.5);
        z-index: 0;
        display: inline-block;
        cursor: crosshair;
        font-family: Mahone;
        line-height: 1em;
    }


}



/**

.next-direction{
    font-size: 50px;
    transform: translateY(600%) scale(5);
    z-index: 0;
    display: inline-block;
    cursor: crosshair;
    font-family: Mahone;
    line-height: 1em;
}

.back-direction{
    font-size: 50px;
    transform:  scale(5);
    z-index: 0;
    display: inline-block;
    cursor: crosshair;
    font-family: Mahone;
    line-height: 1em;
}

*/


/**

.next-direction{
    font-size: 50px;
    transform: translateY(500%) scale(4);
    z-index: 0;
    display: inline-block;
    cursor: crosshair;
}

.back-direction{
    font-size: 50px;
    transform:  scale(4);
    z-index: 0;
    display: inline-block;
    cursor: crosshair;
}


*/